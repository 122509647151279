import detectEthereumProvider from "@metamask/detect-provider";
import Web3 from "web3";

export default {
  data() {
    return {
      provider: null,
      chainId: null,
      accountAddress: null,
      web3: null,
      mmInstalled: false,
      isMetaMask: false,
      isTrustWallet: false,
      isExodus: false,
      useOnlyMetaMask: false,
      statusConnection: false,
      statusChange: false
    };
  },
  async mounted() {
    await this.startMetamask();
  },
  methods: {
    async startMetamask() {
      if (window.ethereum) {
        const provider = await detectEthereumProvider();

        this.isMetaMask = provider?.isMetaMask || false;
        this.isTrustWallet = provider?.isTrustWallet || false;
        this.isExodus = provider?.isExodus || false;

        if (this.isTrustWallet || this.isExodus) {
          this.useOnlyMetaMask = true;
        }

        if (provider) {
          // If the provider returned by detectEthereumProvider is not the same as
          // window.ethereum, something is overwriting it, perhaps another wallet.
          if (provider !== window.ethereum) {
            console.error("Do you have multiple wallets installed?");
            return;
          }
          this.provider = provider;
          this.chainId = await provider.request({ method: "eth_chainId" }); // 0x61

          provider
            .request({ method: "eth_accounts" })
            .then(this.metamaskEventAccountsChanged.bind(this))
            .catch((err) => {
              // Some unexpected error.
              // For backwards compatibility reasons, if no accounts are available,
              // eth_accounts will return an empty array.
              console.error(err);
            });

          provider.on("accountsChanged", this.handleAccountsChanged.bind(this));
          provider.on("connect", this.metamaskEventConnected.bind(this));
          provider.on("disconnect", this.metamaskEventDisconnected.bind(this));
          provider.on(
            "accountsChanged",
            this.metamaskEventAccountsChanged.bind(this)
          );
          provider.on("chainChanged", this.metamaskEventChainChanged.bind(this));
          provider.on("message", this.metamaskEventMessage.bind(this));

          this.mmInstalled = true;
          this.$store.dispatch("mmInstalled", true);
          this.web3 = new Web3(provider);
        }
      }
    },
    metamaskEventAccountsChanged(accounts) {
      if (accounts.length > 0) {
        this.accountAddress = accounts[0];
        this.$store.dispatch("accountAddress", accounts[0]);
      }
    },
    metamaskEventConnected(connectInfo) {
      console.log("metamaskEventConnected");
      console.log(connectInfo);
      // TODO ...
    },
    metamaskEventDisconnected(providerRpcError) {
      console.log("metamaskEventDisconnected");
      console.log(providerRpcError);
    },
    handleAccountsChanged(providerRpcError) {
      if (providerRpcError[0]) {
        this.accountAddress = providerRpcError[0];
        this.$store.dispatch("accountAddress", providerRpcError[0]);
      }
      else {
        this.accountAddress = null;
        this.$store.dispatch("accountAddress", null);
      }
    },
    metamaskEventChainChanged(chainId) {
      console.log("metamaskEventChainChanged");
      console.log(chainId);
      if (chainId !== this.chainId) {
        // TODO this.$store.commit('wallet/setV2ChainId', chainId)
        this.chainId = chainId;
      }
    },
    metamaskEventMessage(msg) {
      console.log("metamaskEventMessage");
      console.log(msg);
      // TODO ...
    },
    // Принудительное подключение
    connectMetamaskAction() {
      this.statusConnection = true;
      const { ethereum } = window;
      ethereum
        .request({ method: "eth_requestAccounts" })
        .then(() => {
          this.statusConnection = false;
          this.metamaskEventAccountsChanged.bind(this);
        })
        .catch((err) => {
          this.statusConnection = false;
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log("Please connect to MetaMask.");
          }
          else {
            this.statusConnection = true;
            console.error(err);
            this.$toast.error(
              this.$t("metaMask.metaMaskPassword"),
              {
                duration: 8000
              }
            );
          }
        });
    },
    // Принудительное открытие
    openMetamaskAction() {
      this.statusChange = true;
      const { ethereum } = window;
      ethereum
        .request({
          method: "wallet_requestPermissions",
          "params": [
            {
              "eth_accounts": {}
            }
          ]
        })
        .then(() => {
          this.statusChange = false;
        })
        .catch((err) => {
          this.statusChange = false;
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log("Please connect to MetaMask.");
          }
          else {
            this.statusChange = true;
            console.error(err);
          }
        });
    },
    // https://eips.ethereum.org/EIPS/eip-3085
    metamaskAddEthereumChain(chain) {
      const { ethereum } = window;
      ethereum
        .request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${chain.chainId.toString(16)}`,
              chainName: chain.name, // Не обязательное поле. Желательно в api chainName сделать
              rpcUrls: [`${chain.entryPointsHttp}`],
              iconUrls: chain.iconUrls ? [`${chain.iconUrls}`] : "", // желательно ссылку на логотип png https...
              nativeCurrency: {
                name: chain.name,
                symbol: chain.symbol,
                decimals: 18
              }
            }
          ]
        })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "success",
            detail: this.$t("metaMask.netAdd"),
            group: "info",
            life: 3000
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: this.$t("metaMask.netError"),
            group: "info",
            life: 3000
          });
        });
    },
    // https://eips.ethereum.org/EIPS/eip-747
    metamaskAddEthereumCoin(token) {
      const { ethereum } = window;
      ethereum
        .request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: token.createdAddress,
              symbol: token.mnemonicEng,
              decimals: token.decimals ? token.decimals : 0,
              image: token.logoMain ? token.logoMain : ""
            }
          }
        })
        .then(() => {
          this.$toast.success(this.$t("metaMask.tokenAdd"));
        })
        .catch(() => {
          this.$toast.error(this.$t("metaMask.tokenError"));
        });
    },

    async sendToken(token, addressTo, amount, fromAddress) {
      let abi = [{
        "inputs": [
          {
            "internalType": "address",
            "name": "to",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "transfer",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      }];

      let amountBN = this.web3.utils.toBN(amount);
      // let decimalsBN = this.web3.utils.toBN(token.decimals);

      const contract = new this.web3.eth.Contract(abi, token.createdAddress);
      // let value = amountBN.mul(this.web3.utils.toBN(10).pow(decimalsBN));
      await contract.methods.transfer(addressTo, amountBN).send({ from: fromAddress });
    }
  }
};
