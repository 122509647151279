<template>
  <WizardWrap
    v-if="$route.fullPath != '/wallet-verification'"
    :texts="texts"
    :stage="stage"
  />
</template>

<script>
import _ from "lodash";
import CurrencySupport from "@/help/currencySupport";
import { mapGetters } from "vuex";

import WizardWrap from "./WizardWrap";

export default {
  components: {
    WizardWrap
  },

  data() {
    return {
      currencySup: new CurrencySupport(),
      texts: [
        {
          label: {
            text: "To start investing with Autentic "
          },
          link: {
            text: "connect your MetaMask wallet to your account.",
            link: "/wallet-verification"
          },
          button: {
            text: "Connect MetaMask",
            link: "/wallet-verification"
          },
          verification: false
        },
        {
          label: {
            text: "To start investing "
          },
          link: {
            text: "deposit funds.",
            link: "/briefcase/buy-form"
          },
          button: {
            text: "Deposit funds",
            link: "/briefcase/buy-form"
          },
          verification: true
        },
        {
          label: {
            text: "Now you can start investing into "
          },
          link: {
            text: "projects.",
            link: "/projects"
          },
          button: {
            text: "Start investing",
            link: "/projects"
          },
          verification: true
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      ethAddresses: "ethAddresses",
      auOwners: "auOwners",
      tokensUtility: "tokensUtility",
      defaultTokenMnemonic: "defaultToken/defaultTokenMnemonic",
      profile: "profile/profile"
    }),

    tokenUSD() {
      const item = _.find(this.tokensUtility, el => el.mnemonicEng === process.env.VUE_APP_TOKEN_DEFAULT);
      return this.currencySup.getAmount(item.id) * this.currencySup.getCurrency(item.id, this.defaultTokenMnemonic);
    },

    stage() {
      if (!this.address || !this.address?.verifiedAt || !this.address?.status) {
        return 0;
      }
      if (!this.tokenUSD) {
        return 1;
      }
      return 2;
    },

    address() {
      return _.get(this.ethAddresses, "[0]", "");
    }
  }
};
</script>